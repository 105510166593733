export const onClientEntry = () => {
  window.onload = () => {
    setTimeout(function() {
      console.log("page is loaded")
      document.getElementById("___loader").style.display = "none"
    }, 5000)
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  return location.hash
}

export const onRouteUpdate = ({ location }) => {
  if (!location.hash) {
    return
  }
  const elem = document.getElementById(location.hash)
  if (!elem) return
  elem.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" })
}
